// Variables
$color-light-green: #A7DA3E;
$color-dark-green: #6FA107;
$color-light-grey: #B6B6B6;
$color-main: #545454;
// Basic / Main Styling
*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.6em;
    color: $color-main;
    background-color: #FFF;
}

a {
    transition: all .2s ease-in-out;
    color: $color-main;
    border-bottom: 2px solid $color-light-grey;
    &:hover {
        text-decoration: none;
        color: $color-main;
        border-bottom: 2px solid $color-dark-green;
    }
}

a.button {
    font-weight: 700;
    display: inline-block;
    margin-top: 2.5rem;
    padding: 1rem 3rem;
    text-transform: uppercase;
    color: #FFF;
    border: 1px solid #FFF;
    background-color: $color-dark-green;
    &:hover {
        text-decoration: none;
        background-color: $color-light-green;
    }
}

p {
    display: inline-block;
}

h2 {
    font-size: 30px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    margin: 0;
    margin-bottom: 6rem;
    text-transform: uppercase;

    &:before {
        position: absolute;
        bottom: -10px;
        left: 15%;
        width: 70%;
        height: 1px;
        content: '';
        border-bottom: 4px solid $color-dark-green;
    }
}

// Header / Navigation Styling
.mainHeader,
.mainNavigation {
    ul {
        font-weight: 700;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        margin: 0;
        list-style: none;
        li {
            margin-right: 2rem;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    a {
        border: none;
        &:hover {
            border: none;
        }
    }
    @media(max-width: 991px) {
        display: none;
    }
}

.mainHeader>.container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    height: 125px;
    .logo {
        width: 325px;
        height: 75px;
        margin: 0;
        text-indent: -9999px;
        background-image: url('../images/logo.svg');
        background-repeat: no-repeat;
    }
    ul {
        -ms-flex-pack: end;
            justify-content: flex-end;
        li {
            text-transform: uppercase;
            a {
                color: $color-main;
                &:hover {
                    text-decoration: underline;
                }
            }
            a.button {
                display: inline;
                padding: .4rem 1.5rem;
                color: #FFF;
                &:hover {
                    text-decoration: none;
                }
            }
            .facebookLink {
                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
    &:before,
    &:after {
        display: none;
        content: '';
    }
}

.mainNavigation {
    ul {
        -ms-flex-pack: center;
            justify-content: center;
        padding: 0;
        border-bottom: 2px solid #FFF;
        background: linear-gradient(to bottom, $color-light-green 0%, $color-dark-green 100%);
        a {
            color: #FFF;
            &:hover {
                text-decoration: none;
            }
        }
        li {
            padding: 1rem;
            margin: 0;
            transition: .2s all ease-in-out;
            &:hover {
                background-color: $color-main;
            }
        }
        li:hover>ul,
        li>ul:hover {
            display: block;
        }
        ul.dropdown-menu {
            font-size: inherit;
            position: absolute;
            top: 170px;
            left: auto;
            display: none;
            float: none;
            min-width: 0;
            border: 1px solid #FFF;
            border-top-width: 2px;
            border-radius: 0;
            background: transparent;
            background-color: $color-dark-green;
            box-shadow: none;
            li {
                margin: 0;
                padding: 0;
                border-bottom: 1px solid #FFF;
                a {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1.6em;
                    padding: .5rem 1.75rem;
                    color: #FFF;
                    background-color: transparent;
                    &:hover {
                        text-decoration: none;
                    }
                }
                &.active a:hover {
                    background-color: transparent;
                }
                &:last-of-type {
                    border-bottom: none;
                }
                &:hover {
                    background-color: $color-main;
                }
            }
        }
    }
    &.is-fixed {
        position: fixed;
        z-index: 999999999;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;

        ul.dropdown-menu {
            top: 45px;
        }
    }
}

.mobileHeader {
    display: none;
    .mobileMainNav {
        display: none;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: center;
            background-color: $color-dark-green;
            li {
                a {
                    font-weight: 700;
                    display: inline-block;
                    width: 100%;
                    padding: 1rem;
                    text-decoration: none;
                    color: #FFF;
                    border-bottom: 1px solid #FFF;
                }
                &:first-of-type {
                    border-top: 1px solid #FFF;
                }
            }
        }
        &.active {
            display: block;
        }
    }
    .closeMobileNav {
        position: absolute;
        top: calc(150px - 22px);
        right: 3rem;
        width: 44px;
        height: 44px;
        background-color: $color-dark-green;
        a {
            font-size: 22px;
            line-height: 44px;
            display: inline-block;
            width: 100%;
            height: 100%;
            text-align: center;
            color: #FFF;
            border: none;
        }
    }
    .mobileTel {
        font-weight: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        a {
            margin: 0 auto;
            padding: .25rem 1.5rem;
            border: none;
        }
    }
    .mobileLogo {
        position: absolute;
        top: 122px;
        left: 3rem;
        .logo {
            width: auto;
            height: 60px;
            margin: 0;
            text-indent: -9999px;
            background-image: url('../images/logo-white.svg');
            background-repeat: no-repeat;
        }
    }
    @media(max-width: 991px) {
        display: block;
    }
}

// Slider / Quotation Styling
.quotationSector {
    height: 500px;
    background-color: $color-light-green;
    background-image: url('../images/header-001.jpg');
    background-position: 50% 75%;
    background-size: cover;
    @media(max-width: 991px) {
        height: 300px;
    }
}

.sliderContent,
.quotationContent {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    h2 {
        font-family: 'Montserrat', sans-serif;
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 4.375rem;
        color: #FFF;
        &:before {
            display: none;
        }
    }
    a.button {
        border: 1px solid #FFF;
    }
    @media(max-width: 991px) {
        h2 {
            margin: 0;
            text-transform: none;
        }
    }
}

.sliderContent {
    position: absolute;
    z-index: 999;
    top: 173px;
    right: 0;
    left: 0;
    height: 500px;
    @media(max-width: 991px) {
        display: none;
    }
}

.quotationSector {
    background-image: url('../images/header-002.jpg');
    background-position: 50% 50%;
}

// AboutUs Section Styling
.aboutUsSection {
    padding: 8rem 15px;
    p,
    h2 {
        font-family: Helvetica, sans-serif;
    }
    h2 {
        margin-bottom: 3.5rem;
        &:before {
            left: 30%;
            width: 40%;
        }
    }
    a.button {
        padding: .5rem 4rem;
    }
    img {
        width: 100%;
        height: auto;
    }
    @media(max-width: 991px) {
        padding: 5rem 15px;
        text-align: center;
        ;
        .col-md-6:first-of-type {
            display: none;
        }
        a.button {
            width: 100%;
            padding: .5rem 4rem;
        }
    }
    @media(max-width: 767px) {
        padding: 5rem 30px;
        ;
    }
}

// Projects / News Section Styling
.projectsSection,
.newsSection {
    padding: 6rem 0;
    background-color: #F5FDE4;
    .container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-pack: center;
            justify-content: center;
    }
    h2 {
        margin-bottom: 6rem;
        text-transform: uppercase;
        &:before {
            left: 15%;
            width: 70%;
        }
    }
    .col-md-4 {
        margin-bottom: 2.5rem;
    }
    .col-md-4>a>div {
        height: 100%;
        padding: 2.5rem;
        background-color: #FFF;
        min-height: 456px;
        img {
            width: calc(100% + 5rem);
            margin: -2.5rem 0 0 -2.5rem;
            margin-bottom: 2.5rem;
            border: 2px solid #FFF;
        }
        h3,
        p {
            margin: 0;
        }
        h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 1rem;
            line-height: 1.5em;
        }
    }
    a.button {
        margin-top: 3.5rem;
    }
    @media (min-width: 992px) {
        .row>.col-md-4:nth-child(3n+1) {
            clear: both;
        }
    }
    @media (min-width: 768px) and (max-width: 992px) {
        .row>.col-sm-6:nth-child(2n+1) {
            clear: both;
        }
    }
    @media(max-width: 991px) {
        padding: 5rem calc(3rem - 15px);
        text-align: center;
        .col-md-4:not(:first-of-type) {
            display: none;
        }
        a.button {
            width: 100%;
            margin: 0;
            margin-top: 2.5rem;
            padding: .5rem 4rem;
        }
    }
}

#projectsPage {
    @media(max-width: 991px) {
        .col-md-4 {
            display: block;
        }
    }
}

// Footer Styling
.mainFooter {
    padding: 6rem 0 0;
    color: #FFF;
    background-color: $color-dark-green;
    .fluid-container {
        border-top: 2px solid #FFF;
        p,
        a {
            font-weight: 700;
        }
        a {
            text-transform: uppercase;
        }
    }
    .mobileFooterNav,
    label {
        display: none;
    }
    ul {
        font-family: Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 6rem;
        padding: 0;
        list-style: none;
        text-align: center;
        text-transform: uppercase;
        li {
            padding: 1.33rem 0;
            border-top: 2px solid #FFF;
            &:last-of-type {
                border-bottom: 2px solid #FFF;
            }
        }
    }
    input,
    textarea {
        font-family: Helvetica, sans-serif;
        font-weight: 700;
        width: 100%;
        margin-bottom: 1rem;
        padding: .5rem;
        text-transform: uppercase;
        color: $color-main;
        border: 1px solid $color-main;
    }
    input[type='submit'] {
        margin: 0;
        margin-top: 7px;
        transition: all .2s ease-in-out;
        color: white;
        border: 1px solid white;
        background-color: transparent;
        &:hover {
            background-color: $color-light-green;
        }
    }
    textarea {
        resize: none;
    }
    iframe {
        min-height: 216px;
        border: 2px solid #FFF;
    }
    a {
        color: #FFF;
        border: none;
    }
    p {
        margin: 0;
        padding: 1rem 0;
    }
    @media(max-width: 991px) {
        padding: 5rem 3rem 0;
        iframe,
        form {
            display: none;
        }
        .fluid-container {
            display: none;
        }
        .mobileFooterNav {
            display: block;
        }
        ul {
            margin-bottom: 5rem;
            text-align: left;
            li {
                padding: 1rem 0;
                border: none;
                &:last-of-type {
                    padding-bottom: 0;
                    border: none;
                }
            }
        }
    }
    @media(max-width: 1200px) {
        ul {
            font-size: 16px;
        }
    }
}

// Content Pagina Styling
.greenBody {
    background-color: #F5FDE4;
    .mainHeader {
        background-color: #FFF;
    }
}

.pull-up {
    margin-top: -175px;
    @media(max-width: 991px) {
        margin: 0 auto;
    }
}

.contentBanner {
    height: 350px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media(max-width: 991px) {
        height: 300px;
    }
}

.mainContent {
    height: 100%;
    min-height: 350px;
    margin-bottom: 6rem;
    padding: 2.5rem 4rem 6rem;
    background-color: #FFF;
    .breadcrumb {
        margin-bottom: 2.5rem;
        padding-left: 0;
        background-color: transparent;
        a {
            font-weight: 700;
            display: inline-block;
            margin: 0 .5rem 2.5rem;
            padding: .5rem 4rem;
            color: #FFF;
            border-bottom: none;
            background-color: $color-dark-green;
            &.crumb_active {
                padding: .5rem 4rem;
                color: #FFF;
                background-color: $color-main;
            }
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
            &:hover {
                text-decoration: underline;
            }
        }
        @media(max-width: 991px) {
            display: none;
        }
    }
    h1 {
        font-size: 30px;
        font-weight: 700;
        position: relative;
        display: inline-block;
        margin: 0;
        margin-bottom: 4rem;
        line-height: 5rem;
        &:before {
            position: absolute;
            bottom: -20px;
            left: 30%;
            width: 40%;
            height: 1px;
            content: '';
            border-bottom: 4px solid $color-dark-green;
        }
    }
    h2 {
        font-size: 24px;
        margin: 0;
        text-transform: none;
        color: $color-dark-green;
        &:before {
            display: none;
        }
    }
    h3 {
        font-size: 18px;
        font-weight: 700;
        font-style: italic;
        margin: 0;
    }
    h2,
    h3 {
        display: block;
        margin-bottom: .5rem;
    }
    p {
        margin-bottom: 2.5rem;
    }
    a.button {
        margin: 0 0 4rem 0;
        padding: .5rem 4rem;
    }
    img {
        width: 100%;
        height: auto;
        margin-bottom: 2.5rem;
    }
    @media(max-width: 991px) {
        width: calc(100% - 60px);
        margin: 0 auto 5rem;
        padding: 5rem 2.5rem;
        text-align: center;
        a.button {
            width: 100%;
        }
        p,
        h2,
        h3 {
            text-align: left;
        }
        p:last-of-type {
            margin: 0;
        }
    }
}

.latestContent {
    text-align: center;
    a.button {
        margin: 0 0 2.5rem 0;
        padding: .5rem 4rem;
    }
    a>div {
        height: 100%;
        margin-bottom: 2.5rem;
        padding: 2.5rem;
        text-align: left;
        background-color: #FFF;
        min-height: 456px;
        img {
            width: calc(100% + 5rem);
            margin: -2.5rem 0 0 -2.5rem;
            margin-bottom: 2.5rem;
            border: 2px solid #FFF;
        }
        h3,
        p {
            margin: 0;
        }
        h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 1rem;
        }
    }
    @media(max-width: 991px) {
        padding: 0 calc(3rem - 15px);
        a>div {
            text-align: center;
        }
        a.button {
            width: calc(100% - 30px);
            margin: 2.5rem auto 5rem;
        }
    }
}

// Slider Styling
.bx-wrapper {
    position: relative;
    margin: 0;
    .bx-viewport {
        height: 500px !important;
        img {
            width: 100%;
            height: 500px;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }
    .bx-controls-direction {
        position: absolute;
        z-index: 1000;
        top: 226px;
        right: 0;
        left: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
            justify-content: space-between;
        width: 90%;
        max-width: 1170px;
        margin: 0 auto;
        a {
            font-size: 0;
            line-height: 45px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            text-align: center;
            color: #FFF;
            border: 2px solid #FFF;
            background-color: $color-main;
        }
        .bx-prev {
            &:before {
                font-family: 'FontAwesome';
                font-size: 24px;
                content: '\f104';
            }
        }
        .bx-next {
            &:before {
                font-family: 'FontAwesome';
                font-size: 24px;
                content: '\f105';
            }
        }
    }
    @media(max-width: 991px) {
        .bx-viewport {
            height: 300px !important;
            img {
                height: 300px;
            }
        }
        .bx-controls-direction {
            display: none;
        }
    }
}

// Contact Section
.contactSection {
    padding: 6rem 0 6rem;
    .col-md-4 {
        height: 400px;
        margin-bottom: 2.5rem;
        &:first-of-type>div {
            height: 100%;
            padding: 2.5rem;
            background-color: #FFF;
        }
        &:nth-of-type(2) div>#form {
            height: 100%;
            padding: 2.5rem;
            color: #FFF;
            background-color: $color-dark-green;
            label {
                display: none;
            }
            input,
            textarea {
                font-family: Helvetica, sans-serif;
                font-size: inherit;
                width: 100%;
                height: auto;
                margin-bottom: 1.5rem;
                padding: .5rem;
                text-transform: uppercase;
                color: $color-main;
                border: none;
                border-radius: 0;
            }
            input[type='submit'] {
                margin: 0;
                margin-top: 7px;
                transition: all .2s ease-in-out;
                color: white;
                border: 1px solid white;
                background-color: transparent;
                &:hover {
                    background-color: $color-light-green;
                }
            }
            textarea {
                min-height: 135px;
                resize: none;
            }
            .container-field {
                position: static;
                float: none;
                width: auto;
                padding: 0;
            }
        }
        &:nth-of-type(3)>div {
            font-weight: 700;
            line-height: 4rem;
            height: 100%;
            padding: 2.5rem;
            color: #FFF;
            background-color: $color-dark-green;
        }
    }
    .col-md-6>img {
        width: 100%;
        height: auto;
    }
    .col-md-6>iframe {
        height: 100%;
        min-height: 337px;
        border: 2px solid #FFF;
    }
    @media(max-width:1200px) {
        .col-md-6>iframe {
            min-height: 276px;
        }
    }
    @media(max-width: 991px) {
        padding: 5rem calc(3rem - 15px) 2.5rem;
        .col-md-6 {
            display: none;
        }
        .col-md-4 {
            height: auto;
            text-align: center;
        }
    }
}

// Fixes

#admin {
    h2,
    h2:before,
    h2:after {
        border: none;
    }
}
